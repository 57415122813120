// Generated by Framer (6b748fa)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Qg5fAyci8", "GXvHKaXvB", "rVZIMI9PF"];

const serializationHash = "framer-IxLh0"

const variantClassNames = {GXvHKaXvB: "framer-v-19ltwwx", Qg5fAyci8: "framer-v-ndaspu", rVZIMI9PF: "framer-v-u8jxed"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {delay: 0, duration: 4, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Complete: "GXvHKaXvB", Inactive: "rVZIMI9PF", Playing: "Qg5fAyci8"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Qg5fAyci8"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Qg5fAyci8", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearduaw2d = activeVariantCallback(async (...args) => {
setVariant("GXvHKaXvB")
})

useOnVariantChange(baseVariant, {default: onAppearduaw2d, rVZIMI9PF: undefined})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1} {...addPropertyOverrides({GXvHKaXvB: {value: transition2}}, baseVariant, gestureVariant)}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ndaspu", className, classNames)} data-framer-name={"Playing"} data-highlight layoutDependency={layoutDependency} layoutId={"Qg5fAyci8"} ref={ref ?? ref1} style={{backgroundColor: "rgba(0, 0, 0, 0.3)", borderBottomLeftRadius: 4, borderBottomRightRadius: 4, borderTopLeftRadius: 4, borderTopRightRadius: 4, ...style}} {...addPropertyOverrides({GXvHKaXvB: {"data-framer-name": "Complete"}, rVZIMI9PF: {"data-framer-name": "Inactive", "data-highlight": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-1cwkstg"} data-framer-name={"Indicator"} layoutDependency={layoutDependency} layoutId={"z57AaigBE"} style={{backgroundColor: "var(--token-6486a9ac-e9e8-4ced-becb-afb4c654ba35, rgb(31, 54, 48))"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-IxLh0.framer-19itpmr, .framer-IxLh0 .framer-19itpmr { display: block; }", ".framer-IxLh0.framer-ndaspu { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 2px; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 400px; will-change: var(--framer-will-change-override, transform); }", ".framer-IxLh0 .framer-1cwkstg { flex: none; height: 100%; overflow: hidden; position: relative; width: 0%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-IxLh0.framer-ndaspu { gap: 0px; } .framer-IxLh0.framer-ndaspu > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-IxLh0.framer-ndaspu > :first-child { margin-left: 0px; } .framer-IxLh0.framer-ndaspu > :last-child { margin-right: 0px; } }", ".framer-IxLh0.framer-v-19ltwwx .framer-1cwkstg { width: 100%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 2
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"GXvHKaXvB":{"layout":["fixed","fixed"]},"rVZIMI9PF":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerby588q_EJ: React.ComponentType<Props> = withCSS(Component, css, "framer-IxLh0") as typeof Component;
export default Framerby588q_EJ;

Framerby588q_EJ.displayName = "Loading Indicator";

Framerby588q_EJ.defaultProps = {height: 2, width: 400};

addPropertyControls(Framerby588q_EJ, {variant: {options: ["Qg5fAyci8", "GXvHKaXvB", "rVZIMI9PF"], optionTitles: ["Playing", "Complete", "Inactive"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerby588q_EJ, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})